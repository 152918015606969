#outlook a {
  padding: 0;
}

span.forecast-discussion p {
  padding-left: 60px;
  padding-right: 40px;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 596px) {
  .desktop {
    visibility: hidden;
    display: none;
  }
  .mobile {
    display: block !important;
  }
  span.text-block p {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media only screen and (max-width: 596px) {
  .small-float-center {
    margin: 0 auto !important;
    float: none !important;
    text-align: center !important;
  }
  .small-text-center {
    text-align: center !important;
  }
  .small-text-left {
    text-align: left !important;
  }
  .small-text-right {
    text-align: right !important;
  }
}

@media only screen and (max-width: 596px) {
  table.body table.container .hide-for-large {
    display: block !important;
    width: auto !important;
    overflow: visible !important;
  }
}

@media only screen and (max-width: 596px) {
  table.body table.container .row.hide-for-large,
  table.body table.container .row.hide-for-large {
    display: table !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 596px) {
  table.body table.container .show-for-large {
    display: none !important;
    width: 0;
    mso-hide: all;
    overflow: hidden;
  }
}

@media only screen and (max-width: 596px) {
  table.body img {
    width: auto !important;
    height: auto !important;
  }
  table.body center {
    min-width: 0 !important;
  }
  table.body .container {
    width: 95% !important;
  }
  table.body .columns,
  table.body .column {
    height: auto !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  table.body .columns .column,
  table.body .columns .columns,
  table.body .column .column,
  table.body .column .columns {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  table.body .collapse .columns,
  table.body .collapse .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  td.small-1,
  th.small-1 {
    display: inline-block !important;
    width: 8.33333% !important;
  }
  td.small-2,
  th.small-2 {
    display: inline-block !important;
    width: 16.66667% !important;
  }
  td.small-3,
  th.small-3 {
    display: inline-block !important;
    width: 25% !important;
  }
  td.small-4,
  th.small-4 {
    display: inline-block !important;
    width: 33.33333% !important;
  }
  td.small-5,
  th.small-5 {
    display: inline-block !important;
    width: 41.66667% !important;
  }
  td.small-6,
  th.small-6 {
    display: inline-block !important;
    width: 50% !important;
  }
  td.small-7,
  th.small-7 {
    display: inline-block !important;
    width: 58.33333% !important;
  }
  td.small-8,
  th.small-8 {
    display: inline-block !important;
    width: 66.66667% !important;
  }
  td.small-9,
  th.small-9 {
    display: inline-block !important;
    width: 75% !important;
  }
  td.small-10,
  th.small-10 {
    display: inline-block !important;
    width: 83.33333% !important;
  }
  td.small-11,
  th.small-11 {
    display: inline-block !important;
    width: 91.66667% !important;
  }
  td.small-12,
  th.small-12 {
    display: inline-block !important;
    width: 100% !important;
  }
  .columns td.small-12,
  .column td.small-12,
  .columns th.small-12,
  .column th.small-12 {
    display: block !important;
    width: 100% !important;
  }
  .body .columns td.small-1,
  .body .column td.small-1,
  td.small-1 center,
  .body .columns th.small-1,
  .body .column th.small-1,
  th.small-1 center {
    display: inline-block !important;
    width: 8.33333% !important;
  }
  .body .columns td.small-2,
  .body .column td.small-2,
  td.small-2 center,
  .body .columns th.small-2,
  .body .column th.small-2,
  th.small-2 center {
    display: inline-block !important;
    width: 16.66667% !important;
  }
  .body .columns td.small-3,
  .body .column td.small-3,
  td.small-3 center,
  .body .columns th.small-3,
  .body .column th.small-3,
  th.small-3 center {
    display: inline-block !important;
    width: 25% !important;
  }
  .body .columns td.small-4,
  .body .column td.small-4,
  td.small-4 center,
  .body .columns th.small-4,
  .body .column th.small-4,
  th.small-4 center {
    display: inline-block !important;
    width: 33.33333% !important;
  }
  .body .columns td.small-5,
  .body .column td.small-5,
  td.small-5 center,
  .body .columns th.small-5,
  .body .column th.small-5,
  th.small-5 center {
    display: inline-block !important;
    width: 41.66667% !important;
  }
  .body .columns td.small-6,
  .body .column td.small-6,
  td.small-6 center,
  .body .columns th.small-6,
  .body .column th.small-6,
  th.small-6 center {
    display: inline-block !important;
    width: 50% !important;
  }
  .body .columns td.small-7,
  .body .column td.small-7,
  td.small-7 center,
  .body .columns th.small-7,
  .body .column th.small-7,
  th.small-7 center {
    display: inline-block !important;
    width: 58.33333% !important;
  }
  .body .columns td.small-8,
  .body .column td.small-8,
  td.small-8 center,
  .body .columns th.small-8,
  .body .column th.small-8,
  th.small-8 center {
    display: inline-block !important;
    width: 66.66667% !important;
  }
  .body .columns td.small-9,
  .body .column td.small-9,
  td.small-9 center,
  .body .columns th.small-9,
  .body .column th.small-9,
  th.small-9 center {
    display: inline-block !important;
    width: 75% !important;
  }
  .body .columns td.small-10,
  .body .column td.small-10,
  td.small-10 center,
  .body .columns th.small-10,
  .body .column th.small-10,
  th.small-10 center {
    display: inline-block !important;
    width: 83.33333% !important;
  }
  .body .columns td.small-11,
  .body .column td.small-11,
  td.small-11 center,
  .body .columns th.small-11,
  .body .column th.small-11,
  th.small-11 center {
    display: inline-block !important;
    width: 91.66667% !important;
  }
  table.body td.small-offset-1,
  table.body th.small-offset-1 {
    margin-left: 8.33333% !important;
  }
  table.body td.small-offset-2,
  table.body th.small-offset-2 {
    margin-left: 16.66667% !important;
  }
  table.body td.small-offset-3,
  table.body th.small-offset-3 {
    margin-left: 25% !important;
  }
  table.body td.small-offset-4,
  table.body th.small-offset-4 {
    margin-left: 33.33333% !important;
  }
  table.body td.small-offset-5,
  table.body th.small-offset-5 {
    margin-left: 41.66667% !important;
  }
  table.body td.small-offset-6,
  table.body th.small-offset-6 {
    margin-left: 50% !important;
  }
  table.body td.small-offset-7,
  table.body th.small-offset-7 {
    margin-left: 58.33333% !important;
  }
  table.body td.small-offset-8,
  table.body th.small-offset-8 {
    margin-left: 66.66667% !important;
  }
  table.body td.small-offset-9,
  table.body th.small-offset-9 {
    margin-left: 75% !important;
  }
  table.body td.small-offset-10,
  table.body th.small-offset-10 {
    margin-left: 83.33333% !important;
  }
  table.body td.small-offset-11,
  table.body th.small-offset-11 {
    margin-left: 91.66667% !important;
  }
  table.body table.columns td.expander,
  table.body table.columns th.expander {
    display: none !important;
  }
  table.body .right-text-pad,
  table.body .text-pad-right {
    padding-left: 10px !important;
  }
  table.body .left-text-pad,
  table.body .text-pad-left {
    padding-right: 10px !important;
  }
  table.menu {
    width: 100% !important;
  }
  table.menu td,
  table.menu th {
    width: auto !important;
    display: inline-block !important;
  }
  table.menu.vertical td,
  table.menu.vertical th,
  table.menu.small-vertical td,
  table.menu.small-vertical th {
    display: block !important;
  }
  table.button.expand {
    width: 100% !important;
  }
}

@media (max-width: 596px) {
  table.body .columns,
  table.body .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  table.body .container {
    width: 100% !important;
  }

  .desc {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
  }

  .hidden-xs {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
  }

  .visible-xs {
    display: inherit !important;
    height: auto !important;
    width: auto !important;
  }

  .row .has-logo {
    padding: 50px 0 0 !important;
  }

  .header-row .has-email-title {
    padding: 28px 0 75px !important;
  }

  .has-logo table img {
    height: 90px !important;
    padding-bottom: 30px !important;
    border-bottom: 1px solid #90adc9 !important;
  }

  .left-line {
    border-left: none !important;
    padding: 0 !important;
  }

  .left-line h3,
  .left-line h5 {
    text-align: center !important;
    margin-bottom: 0 !important;
  }

  .row .has-icon {
    padding: 60px 0 0 !important;
  }

  .row .has-content {
    padding: 20px 0 80px !important;
  }

  .row-fire-discussion .has-icon {
    padding: 50px 0 0 !important;
  }

  .row-fire-discussion .has-content {
    padding-bottom: 110px !important;
  }

  .row-fire-discussion p {
    padding: 0 55px !important;
    text-align: center !important;
  }

  .last-subsection-separated-line {
    margin-bottom: 0;
    margin-top: 60px;
  }

  .th-content {
    padding-bottom: 0 !important;
  }

  .row-warranty .has-logo table img {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }

  .row-almanac .visible-xs > .astronomy-title {
    margin-top: 0 !important;
  }
}
