:root {
  --dial-large-fontsize: clamp(1rem, 6vw, 32px);
  --dial-large-below-decorator-font-size: clamp(0.25rem, 3vw, 0.75rem);
  --dial-large-decorator-font-size: clamp(0.5rem, 4vw, 0.95rem);
  --dial-large-max-min-font-size: clamp(0.5rem, 2.5vw, 0.75rem);
  --dial-large-max-min-bottom-spacing: clamp(0.55rem, 4vw, 1.75rem);
  --pin-subtext-font-size: clamp(0.5rem, 2.5vw, 0.85rem);
  --pin-subtext-bottom-spacing: clamp(1.5rem, 4.5vw, 2rem);
  --pin-triangle-subtext-bottom-spacing: clamp(-0.85rem, -2.5vw, 3rem);
}

html {
  font-size: clamp(16px, 0.7vw, 999px);
  line-height: 1.6;
}

body {
  font-family: Interstate, sans-serif;
}

button {
  font-size: 1rem;
}

a {
  cursor: pointer;
  color: #009ade;
  text-decoration: none;
}
